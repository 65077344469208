.detail-bundling {
}

.detail-bundling__field-array {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 16px;
}

@media only screen and (max-width: 768px) {
    .detail-bundling__field-array {
        grid-template-columns: 1fr;
        row-gap: 16px;
    }
}
