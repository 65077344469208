.katalog__checkbox {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 8px;
}

@media only screen and (max-width: 1024px) {
    .katalog__checkbox {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 768px) {
    .katalog__checkbox {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 375px) {
    .katalog__checkbox {
        grid-template-columns: repeat(1, 1fr);
    }
}
