.range-filter {
  border-radius: 8px;
  min-width: 200px;
  padding-top: 8.5px;
  padding-right: 32px;
  padding-bottom: 8.5px;
  padding-left: 14px;
  border-style: solid;
  border-color: #bdbdbd;
  border-width: 1px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

.range-filter:hover {
  border-color: black;
}
