.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

a {
    color: var(--black87);
    text-decoration: none;
}

input::-ms-reveal,
input::-ms-clear {
    display: none !important;
}

.swal2-container {
    z-index: 10000;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .hide-scroll::-webkit-scrollbar {
    display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .hide-scroll { */
/* -ms-overflow-style: none; IE and Edge */
/* scrollbar-width: none; Firefox */
/* } */

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 8px;
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.swal2-container {
    z-index: 10000 !important;
}

.my-swal {
    z-index: 10000 !important;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.table-cell-trucate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
