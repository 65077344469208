.card-pesan {
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    /* column-gap: 1em; */
}

@media only screen and (max-width: 599px) {
    .card-pesan {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1em;
    }
}
