.custom-slick .slick-prev,
.custom-slick .slick-next {
    /* color: black; */
    color: transparent;
}

.custom-slick .slick-prev:before,
.custom-slick .slick-next:before {
    color: unset;
}
.custom-slick {
    width: 100%;
}

.slider-container {
    width: 620px;
}

@media only screen and (max-width: 1366px) {
    .slider-container {
        width: 500px !important;
    }
}

@media only screen and (max-width: 1280px) {
    .slider-container {
        width: 470px !important;
    }
}
