.pilih-item {
}

.pilih-item .pilih-item__header {
    display: grid;
    grid-template-columns: 1fr 0.5fr auto;
    column-gap: 1em;
}

@media only screen and (max-width: 768px) {
    .pilih-item .pilih-item__header {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 1em;
    }
}
