.scroll_hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.scroll_hide::-webkit-scrollbar {
    display: none;
}

.backdrop {
    background-color: #4a4a4a;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: -10;
}

.modal_container {
    /* width: 100%;
    height: 100%; */
    padding: 4%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
}

.phone_container {
    margin: 0 auto;
    width: 360px;
    min-height: 700px;
    border-radius: 0.7em;
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
}

.phone_header {
    padding: 12px 18px;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
    min-height: 48px;
}

.phone_header > * {
    display: flex;
    color: white;
}

.phone_header > .header_title {
    justify-content: center;
}

.phone_header > .header_left {
    justify-content: start;
}

.phone_header > .header_right {
    justify-content: end;
}

.header_left,
.header_right {
    cursor: pointer;
}

.fab {
    position: absolute;
    bottom: 0px;
    width: 100%;
    border-top: 2px solid lightgrey;
    background-color: white;
}

@media only screen and (max-width: 1024px) {
    .phone_container {
        margin: unset;
        width: 100%;
        min-height: 100vh;
        border-radius: 0;
    }

    .modal_container {
        padding: 0;
    }

    .fab {
        position: fixed;
    }
}
