.card-barang {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 0.5em;
}

@media only screen and (max-width: 768px) {
    .card-barang {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 0.5em;
    }
}
