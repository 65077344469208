.section {
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.section_centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
}

.focused_text {
    font-weight: bold;
    color: #343941;
}

.half_focused_text {
    font-weight: normal;
    color: #6d6d6d;
}

.spacer {
    height: 12px;
    width: 100%;
    background-color: #eeeeee;
}

.product {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.product_container {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.product_info {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.info_title {
    color: #343941;
}

.info_subtitle {
    font-size: 14px;
}

.button {
    display: block;
    padding: 12px 16px;
    color: #ffffff;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
}

.button_outline {
    display: block;
    padding: 12px 16px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    border: none;
    background-color: transparent;
    font-size: 16px;
}

.section_float {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    margin-bottom: 12px;
}

.method_container {
    padding: 6px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    column-gap: 24px;
    align-items: center;
    text-decoration: none;
}

.method_image {
    width: 46px;
    height: 46px;
    border-radius: 4px;
    background-image: linear-gradient(#ffffff, #f3f3f3f3);
    border-width: 1px;
    border-color: #d9d9d9;
    border-style: solid;
    padding: 4px;
}

.method_qrcode {
    width: 150px;
    height: 150px;
    border-radius: 4px;
    background-color: #f3f3f3;
}

.method_spacer {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
}

.badge {
    padding: 6px 8px;
    font-size: 10px;
    font-weight: bold;
}

.badge_warning {
    color: #f7a933;
    border: 1px solid #f7a933;
    border-radius: 32px;
}

.batasPembayaran_content {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.batasPembayaran_content > div:nth-child(2) {
    color: red;
}

.selectedMethod_container {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 16px;
    align-items: center;
}

.selectedMethod_container > .method_image {
    width: 64px;
    height: 64px;
}

.selectedMethod_content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.selectedMethod_mid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
}

.button_icon {
    background-color: transparent;
    padding: 6px;
    outline: none;
    border: none;
}

.selectedMethod_billing {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.selectedMethod_payment {
    font-size: 14px;
}

.selectedMethod_qrcode {
    display: flex;
    justify-content: center;
}

.paymentInformation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5%;
    text-align: center;
}

.paymentInformation_text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 90%;
    margin: auto 0;
    margin-bottom: 5em;
}

.paymentInformation_text > div:nth-child(1) {
    font-size: 26px;
    font-weight: bold;
}

.paymentInformation_text > div:nth-child(2) {
    font-size: 16px;
    color: #6d6d6d;
}

.paymentInformation_text > div:nth-child(3) {
    font-size: 16px;
    color: #6d6d6d;
}
